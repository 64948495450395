.slider {
    -webkit-appearance: none;
    width: 100vw;
    max-width: 500px;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    border-radius: 100px;
    -webkit-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #8C65FF;
    border: 2px solid #fff;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #8C65FF;
    border: 2px solid #fff;
    cursor: pointer;
  }

.slider:focus::-webkit-slider-thumb {
    outline: auto;
    outline-offset: -4px;
}

.slider:focus::-moz-range-thumb {
    outline: auto;
    outline-offset: -4px;
}