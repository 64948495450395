.App {
  text-align: center;
  color: #fff;
}

@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #31214d;
  z-index: -1;
}

.animationCircle {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 33;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.animationCircle:nth-child(0) {
  color: #583C87;
  top: 42%;
  left: 20%;
  animation-duration: 34s;
  animation-delay: -77s;
  transform-origin: 20vw 8vh;
  box-shadow: 40vmin 0 5.635501375314685vmin currentColor;
}
.animationCircle:nth-child(1) {
  color: #FFACAC;
  top: 81%;
  left: 4%;
  animation-duration: 54s;
  animation-delay: -124s;
  transform-origin: -10vw -3vh;
  box-shadow: 40vmin 0 5.860661193644802vmin currentColor;
}
.animationCircle:nth-child(2) {
  color: #E45A84;
  top: 18%;
  left: 68%;
  animation-duration: 150s;
  animation-delay: -167s;
  transform-origin: 6vw -7vh;
  box-shadow: -40vmin 0 5.353261143089465vmin currentColor;
}
.animationCircle:nth-child(3) {
  color: #E45A84;
  top: 80%;
  left: 13%;
  animation-duration: 153s;
  animation-delay: -117s;
  transform-origin: 23vw -23vh;
  box-shadow: -40vmin 0 5.275881756733309vmin currentColor;
}
.animationCircle:nth-child(4) {
  color: #FFACAC;
  top: 13%;
  left: 81%;
  animation-duration: 51s;
  animation-delay: -143s;
  transform-origin: -12vw -21vh;
  box-shadow: 40vmin 0 5.55512235136756vmin currentColor;
}
.animationCircle:nth-child(5) {
  color: #E45A84;
  top: 70%;
  left: 35%;
  animation-duration: 136s;
  animation-delay: -106s;
  transform-origin: 19vw -10vh;
  box-shadow: -40vmin 0 5.4506970347235875vmin currentColor;
}
.animationCircle:nth-child(6) {
  color: #583C87;
  top: 95%;
  left: 9%;
  animation-duration: 119s;
  animation-delay: -88s;
  transform-origin: 12vw 8vh;
  box-shadow: -40vmin 0 5.682627028445486vmin currentColor;
}
.animationCircle:nth-child(7) {
  color: #E45A84;
  top: 73%;
  left: 19%;
  animation-duration: 125s;
  animation-delay: -85s;
  transform-origin: 25vw -13vh;
  box-shadow: -40vmin 0 5.92603786122894vmin currentColor;
}
.animationCircle:nth-child(8) {
  color: #E45A84;
  top: 3%;
  left: 12%;
  animation-duration: 44s;
  animation-delay: -27s;
  transform-origin: 0vw -10vh;
  box-shadow: -40vmin 0 5.9739208925559995vmin currentColor;
}
.animationCircle:nth-child(9) {
  color: #583C87;
  top: 87%;
  left: 14%;
  animation-duration: 54s;
  animation-delay: -55s;
  transform-origin: -1vw -3vh;
  box-shadow: 40vmin 0 5.213833728380801vmin currentColor;
}
.background .animationCircle:nth-child(10) {
  color: #583C87;
  top: 22%;
  left: 53%;
  animation-duration: 102s;
  animation-delay: -72s;
  transform-origin: 8vw 3vh;
  box-shadow: 40vmin 0 5.890821355666467vmin currentColor;
}
.background .animationCircle:nth-child(11) {
  color: #E45A84;
  top: 90%;
  left: 35%;
  animation-duration: 17s;
  animation-delay: -8s;
  transform-origin: 11vw 7vh;
  box-shadow: 40vmin 0 5.068523068583419vmin currentColor;
}
.animationCircle:nth-child(12) {
  color: #E45A84;
  top: 43%;
  left: 89%;
  animation-duration: 19s;
  animation-delay: -44s;
  transform-origin: 11vw -3vh;
  box-shadow: -40vmin 0 5.150986100369352vmin currentColor;
}
.animationCircle:nth-child(13) {
  color: #583C87;
  top: 36%;
  left: 19%;
  animation-duration: 165s;
  animation-delay: -38s;
  transform-origin: -16vw 17vh;
  box-shadow: -40vmin 0 5.883857963192769vmin currentColor;
}
.animationCircle:nth-child(14) {
  color: #E45A84;
  top: 80%;
  left: 91%;
  animation-duration: 89s;
  animation-delay: -5s;
  transform-origin: -8vw -7vh;
  box-shadow: -40vmin 0 5.55549815875474vmin currentColor;
}
.animationCircle:nth-child(15) {
  color: #FFACAC;
  top: 83%;
  left: 91%;
  animation-duration: 146s;
  animation-delay: -15s;
  transform-origin: 14vw -5vh;
  box-shadow: -40vmin 0 5.035250413942424vmin currentColor;
}
.animationCircle:nth-child(16) {
  color: #FFACAC;
  top: 43%;
  left: 28%;
  animation-duration: 54s;
  animation-delay: -68s;
  transform-origin: 15vw 7vh;
  box-shadow: 40vmin 0 5.873751521758672vmin currentColor;
}
.animationCircle:nth-child(17) {
  color: #E45A84;
  top: 39%;
  left: 2%;
  animation-duration: 60s;
  animation-delay: -26s;
  transform-origin: 4vw -17vh;
  box-shadow: -40vmin 0 5.279606937919199vmin currentColor;
}
.animationCircle:nth-child(18) {
  color: #583C87;
  top: 84%;
  left: 44%;
  animation-duration: 142s;
  animation-delay: -122s;
  transform-origin: 19vw 18vh;
  box-shadow: -40vmin 0 5.330535140563021vmin currentColor;
}
.animationCircle:nth-child(19) {
  color: #FFACAC;
  top: 77%;
  left: 62%;
  animation-duration: 175s;
  animation-delay: -80s;
  transform-origin: 2vw -4vh;
  box-shadow: -40vmin 0 5.945184281269309vmin currentColor;
}
